<template>
    <base-layout>
        <template v-slot:sidebar>
            <app-sidebar-details :title="sidebar_title" @close-sidebar="closeSidebar">
                <allocation-details
                    :uuid="allocation_uuid"

                    ref="allocation-details"

                    @close-sidebar="closeSidebar"
                    @saved="getNumberAllocations"
                ></allocation-details>
            </app-sidebar-details>
        </template>

        <div class="page-number-manager-allocations container">
            <div class="row">
                <div class="col-12">
                    <h1 class="heading">Number Allocations</h1>
                </div>

                <div class="col-12">
                    <app-error v-model="errors.show" :message="errors.message"></app-error>

                    <app-table
                        class="number-allocations-table"

                        :sort-value="filter.sort"
                        :sort-options="sort"
                        @sort-change="onSortChange"

                        :cols="cols"
                        :rows="rows"

                        :loading="loading"

                        :selected-row="allocation_uuid"

                        @edit-allocation="onEditAllocation"
                    />

                    <div class="actions">
                        <app-pagination
                            v-if="pagination.total"

                            v-model="pagination.page"

                            :total="pagination.total"
                            :limit="pagination.limit"
                            :page-range="pagination.range"

                            @change="onPageChange"
                        />

                        <!-- <button class="btn btn-primary btn-add-individual-number" :disabled="loading" @click="addIndividualNumber">Add individual number</button> -->
                    </div>
                </div>
            </div>
        </div>
    </base-layout>
</template>

<script>
import { mapGetters } from 'vuex'

import baseLayout from '@/views/layouts/base-layout'
import appSidebarDetails from '@/components/app-sidebar-details'

import allocationDetails from '@/views/number-manager/allocation'

import appError from '@/components/app-error'
import appTable from '@/components/app-table'
import appPagination from '@/components/app-pagination'

import errMessage from '@/helpers/errMessage'

// import testNumberAllocations from './test-number-allocations.json'

export default {
    components: {
        baseLayout,
        appSidebarDetails,

        allocationDetails,

        appError,
        appTable,
        appPagination,
    },

    data() {
        return {
            filter: {
                sort: {
                    field: 'NumberAllocationStatus',
                    order: 'asc',
                },
            },

            sort: [
                { field: 'PhoneNumber',            title: 'Phone number',             },
                { field: 'NumberAllocationStatus', title: 'Number Allocation Status', },
            ],

            cols: [
                { key: 'PhoneNumber',            title: 'Phone number',             sort: { field: 'PhoneNumber' },            },
                { key: 'NumberAllocationStatus', title: 'Number Allocation Status', sort: { field: 'NumberAllocationStatus' }, },
                { key: 'PhoneNumberRange',       title: 'Phone Number Range',                                                  },
                { key: 'CustomerUUID',           title: 'Customer UUID',            multiline: true                            },
                { key: 'ServiceUUID',            title: 'Service UUID',             multiline: true                            },

                { key: 'edit', action: 'edit-allocation', icon: 'edit', behavior: 'detached', },
            ],

            number_allocations: [],

            loading: false,

            pagination: {
                page: 1,
                total: 0,
                limit: 10,
                range: 2,
            },

            sidebar: {
                create: false,
                edit: false,
            },

            allocation_uuid: null,

            is_mobile_mode: false,

            errors: {
                show: false,
                message: '',
            },
        }
    },

    created() {
        this.init()
    },

    mounted() {
        this.handleWindowResize()

        window.addEventListener('resize', this.handleWindowResize)
    },

    methods: {
        init() {
            if (this.$route.params.page) {
                this.pagination.page = +this.$route.params.page
            }

            this.getNumberAllocations()
        },

        handleWindowResize() {
            this.is_mobile_mode = document.body.clientWidth <= this.$mobile_size
        },

        onSortChange(sort) {
            this.filter.sort = sort

            this.getNumberAllocations()
        },

        onPageChange(page) {
            this.getNumberAllocations(page)
        },

        getNumberAllocations(page) {
            this.loading = true

            const params = {
                'SPID':                     this.current_spid,
                'SearchOptions.PageNumber': page ? page : this.pagination.page,
                'SearchOptions.PageSize':   this.pagination.limit,
                'SearchOptions.SortBy':     this.filter.sort.field,
                'SearchOptions.SortDesc':   this.filter.sort.order === 'desc' ? 1 : 0,
            }

            this.$store.dispatch('api_phonenumber/GetPhoneNumbersPaginated', params)
                .then(response => {
                    this.number_allocations = response.PhoneNumbers

                    this.pagination.page = response.PageInfo.PageNumber
                    this.pagination.total = response.PageInfo.TotalItemCount

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({
                            name: this.$route.name,
                            params: { page: this.pagination.page },
                            replace: true,
                        })
                    }

                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.loading = false
                })
                .catch(error => {
                    this.errors = {
                        show: true,
                        message: errMessage(error),
                    }

                    this.number_allocations = []

                    this.loading = false
                })
        },

        /*
            getNumberAllocations(page) {
                const delay_in_ms = 1000

                this.loading = true

                setTimeout(() => {
                    const list = []

                    const start = ( ( page || this.pagination.page ) - 1 ) * this.pagination.limit
                    const total = testNumberAllocations.length
                    const end = start + this.pagination.limit < total ? start + this.pagination.limit : total

                    for (let i = start; i < end; i++) {
                        list.push(testNumberAllocations[i])
                    }

                    this.number_allocations = list

                    this.pagination.page = page || this.pagination.page
                    this.pagination.total = total

                    if (this.$route.params.page != this.pagination.page
                        && !(
                            this.$route.params.page === undefined
                            && this.pagination.page === 1
                        )
                    ) {
                        this.$router.push({ name: this.$route.name, params: { page: this.pagination.page }, replace: true })
                    }

                    this.errors = {
                        show: false,
                        message: '',
                    }

                    this.loading = false
                }, delay_in_ms)
            },
        */

        onEditAllocation(row) {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'number-manager-allocation-edit', params: { uuid: row.uuid } })
            } else {
                this.allocation_uuid = row.uuid

                this.sidebar.create = false
                this.sidebar.edit   = true

                this.openSidebar()
            }
        },

        addIndividualNumber() {
            if (this.is_mobile_mode) {
                this.$router.push({ name: 'number-manager-allocation-create' })
            } else {
                this.allocation_uuid = null

                this.sidebar.create = true
                this.sidebar.edit   = false

                this.openSidebar()
            }
        },

        openSidebar() {
            this.$emit('open-sidebar')
        },

        closeSidebar() {
            this.allocation_uuid = null

            this.$emit('close-sidebar')

            this.$refs['allocation-details'].reset()
        },
    },

    computed: {
        ...mapGetters([
            'current_spid',
            '$mobile_size',
        ]),

        sidebar_title() {
            let title = ''

            switch (true) {
                case this.sidebar.create: {
                    title = 'Add individual number'
                } break

                case this.sidebar.edit: {
                    title = 'Edit individual number'
                } break
            }

            return title
        },

        rows() {
            return this.number_allocations.map(allocation => {
                let NumberAllocationStatus = allocation.NumberAllocationStatus ? allocation.NumberAllocationStatus : '?'

                switch (NumberAllocationStatus) {
                    case 'NUMBER_ALLOCATED_ON_NET': {
                        NumberAllocationStatus = 'In Use'
                    } break

                    case 'NUMBER_ALLOCATED_OFF_NET': {
                        NumberAllocationStatus = 'Ported Out'
                    } break

                    case 'NUMBER_COOLDOWN': {
                        NumberAllocationStatus = 'Cooldown'
                    } break
                }

                return {
                    uuid: allocation.UUID,

                    PhoneNumber: allocation.PhoneNumber ? allocation.PhoneNumber : '?',
                    NumberAllocationStatus,
                    PhoneNumberRange: `${ allocation.RangeStartInt } - ${ allocation.RangeEndInt }`,
                    CustomerUUID: allocation.CustomerUUID ? allocation.CustomerUUID : '?',
                    ServiceUUID: allocation.ServiceUUID ? allocation.ServiceUUID : '?',
                }
            })
        },
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleWindowResize)
    },
}
</script>

<style lang="scss">
.page-number-manager-allocations {
    h1 {
        &.heading {
            margin-bottom: 48px;
        }
    }

    .app-error {
        margin-bottom: 24px;
    }

    .number-allocations-table {
        @include table-cols-width((150px, 250px, 300px, 300px, 300px, 48px), true, 1);
    }

    & > .row {
        & > .col-12 {
            & > .actions {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .app-pagination,
                .btn-add-individual-number {
                    margin-top: 30px;
                }

                .app-pagination {
                    flex-grow: 1;

                    margin-right: 16px;
                }
            }
        }
    }
}

@media (max-width: $tablet-size) {
    .page-number-manager-allocations {
        .number-allocations-table {
            @include table-cols-width((135px, 210px, 255px, 255px, 255px, 48px), true, 1);
        }

        & > .row {
            & > .col-12 {
                & > .actions {
                    flex-direction: column;

                    .app-pagination {
                        margin-right: 0;
                    }
                }
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-number-manager-allocations {
        h1 {
            &.heading {
                margin-bottom: 32px;
            }
        }

        .number-allocations-table {
            @include table-cols-width-mobile((90px, 160px, 24px), true);
        }

        & > .row {
            & > .col-12 {
                & > .actions {
                    .app-pagination,
                    .btn-add-individual-number {
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}
</style>